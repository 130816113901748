@import url('https://fonts.googleapis.com/css?family=Cinzel');

body{
  background: rgb(28,30,42);
  background: linear-gradient(90deg, rgba(28,30,42,1) 0%, rgba(3,14,36,1) 51%, rgba(28,30,45,1) 100%);
}

#root{
  overflow-y: hidden;
  overflow-x: hidden;
  text-align: center;
  background: rgb(28,30,42);
  background: linear-gradient(90deg, rgba(28,30,42,1) 0%, rgba(3,14,36,1) 51%, rgba(28,30,45,1) 100%);
}
.App {
  text-align: center;
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.Tile{
  width: calc(90vw / 1);
  /* height:calc(90vh / 2); */
  display: inline-block;
  vertical-align: middle;
  margin:2px;
  border-radius: 2px;
  padding-top:2px;
  
}

.Tile :hover{
  cursor: pointer;
}

.Gallery{

}

.large-image{
  width:80vw;
  max-height: 60vh;
  padding-top:10px;
  object-fit: contain;
}


@media screen and (min-width: 600px) {
  .Tile{
    width: calc(90vw / 2);
    /* height:calc(90vw / 3); */
  }
}

@media screen and (min-width: 900px) {
  .Tile{
    width: calc(90vw / 3);
    height:calc(90vw / 4);
  }
}

@media screen and (min-width: 1200px) {
  .Tile{
    width: calc(90vw / 4);
    height:calc(90vw / 5);
  }
}

.image-wrapper{
  left:1%;
  top:1%;
  position: relative;
  /* background-color: red; */
  width:98%;
  height:98%;
  overflow: hidden;

}

.image-main{
  width:100%;
  height: 100%;
  object-fit: cover;
  object-position:top;
}



  h1{
    color:#fffeff;
    font-size: 32px;
    font-family: Cinzel;
  }

  h2{
    color:#d4a95b;
    font-size: 16px;
    font-family: Cinzel;
  }

  h3{
    color:#fffeff;
    font-size: 12px;
    font-family: Cinzel;
    margin-top: -10px;
  }

.gallery-header{
  padding-top:10px;
}

  .gallery-logo{
    display: inline-block;
    width: 100px;
  }

  .close-model{
    position: absolute;
    top:8px;
    right:11px;
    cursor:pointer;
}

.close-model :hover{
  transform: scale(1.1);
  cursor:pointer;
}

.button-bar{
  display: flex;
  text-align: center;
  justify-content: center;
  /* padding-left:10px */
}

.model-body{
  padding:10px;
  text-align: center;
}

.info-message{
  color:black;
}


